import React, { useState, createContext, useEffect } from 'react';

const MasterSettingsContext = createContext<any>(null);

const MasterSettingsProvider = (props: any) => {
  // Function to retrieve saved settings from localStorage or use defaults
  const getInitialSettings = () => {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const configurationData = userData?.data?.configurationData || {};

    return {
      departmentName: configurationData.departmentConf || 'Department',
      departmentType: configurationData.departmentTypeConf || 'Department Type',
      tools: configurationData.toolConf || 'Tools',
    };
  };

  // State to hold current master settings, initialized from localStorage
  const [masterName, setMasterName] = useState(getInitialSettings);

  useEffect(() => {
    // Optionally: Sync settings if they change in localStorage
    const storedSettings = getInitialSettings();
    setMasterName(storedSettings);
  }, []);

  // Functions to update settings for each field
  const updateDepartmentName = (newName: string) => {
    console.log('Function called with name', newName);
    setMasterName((prevSettings) => ({
      ...prevSettings,
      departmentName: newName || prevSettings.departmentName,
    }));
  };

  const updateDepartmentType = (newType: string) => {
    setMasterName((prevSettings) => ({
      ...prevSettings,
      departmentType: newType || prevSettings.departmentType,
    }));
  };

  const updateToolsName = (newToolsName: string) => {
    setMasterName((prevSettings) => ({
      ...prevSettings,
      tools: newToolsName || prevSettings.tools,
    }));
  };

  return (
    <MasterSettingsContext.Provider
      value={{
        masterName,
        updateDepartmentName,
        updateDepartmentType,
        updateToolsName,
      }}
    >
      {props.children}
    </MasterSettingsContext.Provider>
  );
};

export { MasterSettingsProvider, MasterSettingsContext };
