import * as React from 'react'
import { useState } from 'react'
import OrganisationContext from './OrganisationProvider'
import { InstructionMediaType } from 'src/config/api.config'

interface Instructions {
  title: string
  order: number
  description: string
  media: string
  media_type: number
}
interface ProfileInterface {
  imageUrl: string
  baseUrl: string
}
const initialProfile = {
  baseUrl: '',
  imageUrl: ''
}
const initialVideo = {
  baseUrl: '',
  imageUrl: ''
}

const OrganisationState = (props: any) => {
  const [showActiveSupport, setShowActiveSupport] = useState(false)
  const [createOrgList, setCreateOrgtList] = useState(false)
  const [createUserAgentList, setCreateUserAgentList] = useState(false)
  const [victimcount, setvictimCount] = useState<number>(0)
  const [witnesscount, setwitnessCount] = useState<number>(0)
  const [suspectcount, setsuspectCount] = useState<number>(0)
  const [propertycount, setpropertyCount] = useState<number>(0)
  const [othercount, setotherCount] = useState<number>(0)

  const [statusChange, setStatusChange] = useState(false)
  const [changepasstoggle, setchangepasstoggle] = React.useState(false)
  const [addUserAgenttoggle, setaddUserAgenttoggle] = React.useState(false)
  const [addUserAgentId, setaddUserAgentId] = useState<number>(0)
  const [displayAlluserType, setDisplayAlluserType] = React.useState(false)
  const [addUserTypetoggle, setaddUserTypetoggle] = React.useState(false)
  const [Usertypeid, setUsertypeid] = useState<number>(0)
  const [departmentId, setdepartmentId] = useState<number>(0)
  const [departmenttypeId, setdepartmenttypeId] = useState<number>(0)
  const [toolId, setToolId] = useState<number>(0)
  const [getupdateadmin, setupdateadmin] = useState(false)

  //Work Instruction
  const [mediaPath, setMediaPath] = useState<string>('')
  const [mediaType, setMediaType] = useState<string>(InstructionMediaType.Text)
  const [instruction, setInstructions] = useState<Instructions[]>([])
  const [profilePic, setProfilePic] = useState<ProfileInterface>(initialProfile)
  const [selectedVideo, setSelectedVideo] = useState(initialVideo)
  const [tabValue, setTabValue] = useState('3')
  const [taskId, setTaskId] = useState<number>(0)
  const [updateList, setUpdateList] = useState(false)
  const [editInstruction, setEditInstruction] = useState({})
  const [editId, setEditId] = useState<number>(0)

  //Import tools
  const [datafeedfileName, setdatafeedFileName] = useState()

  //Filter Add user and agent
  const [userFilter, setUserFilter] = useState({
    deptId: 0,
    typeId: 0,
    toolId:0,
  })

  const [removeFilter, setRemoveFilter] = useState(false)

  return (
    <>
      <OrganisationContext.Provider
        value={{
          showActiveSupport,
          setShowActiveSupport,
          createUserAgentList,
          setCreateUserAgentList,
          setvictimCount,
          victimcount,
          witnesscount,
          setwitnessCount,
          suspectcount,
          setsuspectCount,
          propertycount,
          setpropertyCount,
          othercount,
          setotherCount,
          setCreateOrgtList,
          createOrgList,
          statusChange,
          setStatusChange,
          changepasstoggle,
          setchangepasstoggle,
          addUserAgenttoggle,
          setaddUserAgenttoggle,
          addUserAgentId,
          setaddUserAgentId,
          displayAlluserType,
          setDisplayAlluserType,
          addUserTypetoggle,
          setaddUserTypetoggle,
          Usertypeid,
          setUsertypeid,
          departmentId,
          setdepartmentId,
          departmenttypeId,
          setdepartmenttypeId,
          getupdateadmin,
          setupdateadmin,
          mediaPath,
          setMediaPath,
          mediaType,
          setMediaType,
          instruction,
          setInstructions,
          profilePic,
          setProfilePic,
          selectedVideo,
          setSelectedVideo,
          tabValue,
          setTabValue,
          taskId,
          setTaskId,
          updateList,
          setUpdateList,
          editInstruction,
          setEditInstruction,
          editId,
          setEditId,
          datafeedfileName,
          setdatafeedFileName,
          userFilter,
          setUserFilter,
          removeFilter,
          setRemoveFilter,
          toolId,
          setToolId
        }}
      >
        {props.children}
      </OrganisationContext.Provider>
    </>
  )
}

export default OrganisationState
