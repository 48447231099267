import { lazy, useEffect, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import NProgress from 'nprogress'
import BlankLayout from 'src/@core/layouts/BlankLayout'

// import UserLayout from 'src/layouts/UserLayout'
// import LoginWith from './pages/LoginWith'
// import LoginPage from 'src/pages/login'
// import DashboardPage from 'src/pages/admin/dashboard'
// import OrganisationPage from 'src/pages/admin/dashboard/organisations'
// import UserAgentPage from 'src/pages/admin/dashboard/user_agents_list'
// import AddOrganisationPage from 'src/pages/admin/dashboard/organisations/add_organisation'
// import MasterDepartment from 'src/pages/admin/department'
// import DepartmentTypePage from 'src/pages/admin/dashboard/orgaization_type'
// import AccountSettings from 'src/pages/admin/setting/AccountSettings'
// import ChangePasswordAdmin from 'src/pages/admin/setting/changePasswordAdmin'
// import Notifications from 'src/pages/admin/setting/Notifications'
// import SettingsPageHelp from 'src/pages/admin/setting/SettingsPageHelp'

const UserLayout = lazy(() => import('src/layouts/UserLayout'))
const LoginWith = lazy(() => import('./pages/LoginWith'))
const LoginPage = lazy(() => import('src/pages/login'))
const DashboardPage = lazy(() => import('src/pages/admin/dashboard'))
const OrganisationPage = lazy(() => import('src/pages/admin/dashboard/organisations'))
const UserAgentPage = lazy(() => import('src/pages/admin/dashboard/user_agents_list'))
const AddOrganisationPage = lazy(() => import('src/pages/admin/dashboard/organisations/add_organisation'))
const MasterDepartment = lazy(() => import('src/pages/admin/department'))
const DepartmentTypePage = lazy(() => import('src/pages/admin/dashboard/orgaization_type'))
const AccountSettings = lazy(() => import('src/pages/admin/setting/AccountSettings'))
const MasterDisplaySettings = lazy(() => import('src/pages/admin/setting/MasterSettings'))

const ChangePasswordAdmin = lazy(() => import('src/pages/admin/setting/changePasswordAdmin'))
const Notifications = lazy(() => import('src/pages/admin/setting/Notifications'))
const SettingsPageHelp = lazy(() => import('src/pages/admin/setting/SettingsPageHelp'))
const WorkInstruction = lazy(() => import('src/pages/admin/dashboard/work_instruction'))
const WorkInstructionPage = lazy(() => import('src/pages/admin/dashboard/work_instruction/Addworkinstruction'))
const MobileWorkInstructionPage = lazy(
  () => import('src/pages/admin/dashboard/work_instruction/components/MobileSlider')
)
const ToolMaster = lazy(() => import('src/pages/admin/toolmaster'))

// User Layout

// import UserLayouting from 'src/userLayout/Layout'
// import UserLogin from 'src/pages/user/auth/userLogin'
// import UserForgotPass from 'src/pages/user/auth/userForgotPass'
// import Knowledge from 'src/views/apps/chat/KnowledgeUser/Knowledge'
// import UserKnowledgeCategory from './views/apps/chat/KnowledgeUser/UserKnowledgeCategory'

// import SupportTicketDetail from 'src/views/apps/chat/ticketDetails/SupportTicketDetail'
// import OffenceTicketDetail from 'src/views/apps/chat/ticketDetails/OffenceTicketDetail'

// import VideoCallingPage from 'src/views/apps/chat/chatContent/videocalling'

const UserLayouting = lazy(() => import('src/userLayout/Layout'))
const UserLogin = lazy(() => import('src/pages/user/auth/userLogin'))
const UserForgotPass = lazy(() => import('src/pages/user/auth/userForgotPass'))
const Knowledge = lazy(() => import('src/views/apps/chat/KnowledgeUser/Knowledge'))
const UserKnowledgeCategory = lazy(() => import('./views/apps/chat/KnowledgeUser/UserKnowledgeCategory'))
const SupportTicketDetail = lazy(() => import('src/views/apps/chat/ticketDetails/SupportTicketDetail'))
const OffenceTicketDetail = lazy(() => import('src/views/apps/chat/ticketDetails/OffenceTicketDetail'))
const VideoCallingPage = lazy(() => import('src/views/apps/chat/chatContent/videocalling'))

const Profile = lazy(() => import('src/views/apps/chat/profile'))

// import UserDashboard from 'src/pages/apps/chat/index'

//common for all
// import SettingsPage from 'src/pages/admin/setting'
// import NotificationPage from 'src/pages/admin/notification'
// import KnowledgeBase from './pages/admin/dashboard/knowledge/Knowledgebase'
// import KnowledgeCategory from './pages/admin/dashboard/knowledge/KnowledgeCategory'
// import UserKnowledgeArticle from './views/apps/chat/KnowledgeUser/UserKnowledgeArticle'
// import UserKnowledgeFaq from './views/apps/chat/KnowledgeUser/UserKnowledgeFaq'
// import UserRoleTypePage from 'src/pages/admin/userrole'

// import AddArticle from './pages/admin/dashboard/knowledge/AddArticle'

// import ChatContent from './views/apps/chat/chatContent'
// import KnowledgeLayout from './types/knowledgeLayout/KnowledgeLayout'
// import TemplateDashboard from './pages/admin/dashboard/knowledge/templateDashboard'
// import AddTemplatePage from './pages/admin/dashboard/knowledge/template/addTemplate'
// import DisplayArticleView from './pages/admin/dashboard/knowledge/displayArticleView'
// import MyTemplate from './pages/admin/dashboard/knowledge/template/template'
//const KnowledgeBase = lazy(() => import('./pages/admin/dashboard/knowledge/Knowledgebase'))

const NotificationPage = lazy(() => import('src/pages/admin/notification'))
const KnowledgeBase = lazy(() => import('./pages/admin/notification'))
const KnowledgeCategory = lazy(() => import('./pages/admin/dashboard/knowledge/KnowledgeCategory'))
const UserKnowledgeArticle = lazy(() => import('./views/apps/chat/KnowledgeUser/UserKnowledgeArticle'))
const UserKnowledgeFaq = lazy(() => import('./views/apps/chat/KnowledgeUser/UserKnowledgeFaq'))
const UserRoleTypePage = lazy(() => import('src/pages/admin/userrole'))

const AddArticle = lazy(() => import('./pages/admin/dashboard/knowledge/AddArticle'))

const ChatContent = lazy(() => import('./views/apps/chat/chatContent'))
const KnowledgeLayout = lazy(() => import('./types/knowledgeLayout/KnowledgeLayout'))
const TemplateDashboard = lazy(() => import('./pages/admin/dashboard/knowledge/templateDashboard'))
const AddTemplatePage = lazy(() => import('./pages/admin/dashboard/knowledge/template/addTemplate'))
const DisplayArticleView = lazy(() => import('./pages/admin/dashboard/knowledge/displayArticleView'))
const MyTemplate = lazy(() => import('./pages/admin/dashboard/knowledge/template/template'))
const SliderPage = lazy(() => import('src/slider'))
const SignUpPage = lazy(() => import('./views/apps/chat/signup'))
const TermandCondition = lazy(() => import('./views/apps/chat/signup/TermandCondition'))

//ESME Routing
const EsmeLayouting = lazy(() => import('src/userLayout/EsmeLayout'))
const EsmeChatContent = lazy(() => import('./views/apps/chat/EsmeModule/EsmeChatContent'))

function SuspenseLoader() {
  useEffect(() => {
    NProgress.start()

    return () => {
      NProgress.done()
    }
  }, [])

  return <></>
}

const AppRoutes = () => {
  const user = JSON.parse(localStorage.getItem('userData') || '{}')

  // const user = {
  //   "id": 1,
  //   "username": "john_doe",
  //   "email": "john.doe@example.com",
  //   "role": "admin",
  //   "token": "fake_token_here"
  // };

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Routes>
        <Route path='/' element={<BlankLayout />}>
          <Route path='/' element={<Navigate to={Object.keys(user).length ? '/dashboard' : '/loginWith'} />} />
          <Route path='/loginWith' element={<LoginWith />} />
          <Route path='/login' element={<LoginPage />} />

          <Route path='/' element={<UserLayout />}>
            <Route path='dashboard' element={<DashboardPage />} />
            <Route path='company' element={<OrganisationPage />} />
            <Route path='company/add' element={<AddOrganisationPage />} />
            <Route path='company/edit' element={<AddOrganisationPage />} />
            <Route path='company/view' element={<AddOrganisationPage />} />
            <Route path='user_sme' element={<UserAgentPage />} />
            <Route path='work_instruction' element={<WorkInstruction />} />
            <Route path='work_instruction/add' element={<WorkInstructionPage />} />
            {/* <Route path='setting' element={<SettingsPage />} /> */}
            <Route path='notification' element={<NotificationPage />} />
            <Route path='knowledge' element={<KnowledgeBase />} />
            <Route path='/knowledge/category' element={<KnowledgeCategory />} />
            <Route path='knowledge/addArticle' element={<AddArticle />} />
            <Route path='knowledge/template' element={<TemplateDashboard />} />
            <Route path='template/add' element={<AddTemplatePage />} />
            <Route path='template/view' element={<AddTemplatePage />} />
            <Route path='template/edit' element={<AddTemplatePage />} />
            <Route path='knowledge/template' element={<MyTemplate />} />
            <Route path='knowledge/article/preview' element={<DisplayArticleView />} />
            <Route path='userrole' element={<UserRoleTypePage />} />
            <Route path='master/departmentType' element={<MasterDepartment />} />
            <Route path='department' element={<DepartmentTypePage />} />
            <Route path='setting' element={<AccountSettings />} />
            <Route path='mastersetting' element={<MasterDisplaySettings />} />

            <Route path='change_password' element={<ChangePasswordAdmin />} />
            <Route path='notifications' element={<Notifications />} />
            <Route path='help' element={<SettingsPageHelp />} />
            <Route path='master/tools' element={<ToolMaster />} />
          </Route>
        </Route>

        {/* UserLayouting */}
        <Route path='/userLogin' element={<UserLogin />} />
        <Route path='/userForgotPassword' element={<UserForgotPass />} />
        <Route path='/videocalling' element={<VideoCallingPage />} />
        <Route path='/work_instructions/:taskId' element={<MobileWorkInstructionPage />} />
        <Route path='/demo_wi' element={<SliderPage />} />
        <Route path='/signup' element={<SignUpPage />} />
        <Route path='/terms-and-condition' element={<TermandCondition />} />

        {/* <Route path='/esme/profile' element={<Profile />} /> */}
        <Route path='/esme/profile/:userId' element={<Profile />} />

        <Route path='/user' element={<UserLayouting />}>
          <Route path='/user/dashboard' element={<ChatContent />} />
          <Route path='/user/dashboard/support/:id' element={<SupportTicketDetail />} />
          <Route path='/user/dashboard/offence/:id' element={<OffenceTicketDetail />} />
          <Route path='work_instruction' element={<WorkInstruction />} />
          <Route path='work_instruction/add' element={<WorkInstructionPage />} />
        </Route>
        <Route path='/user/knowledge' element={<KnowledgeLayout />}>
          <Route path='/user/knowledge' element={<Knowledge />} />
          <Route path='/user/knowledge/faq' element={<UserKnowledgeFaq />} />
          <Route path='/user/knowledge/article/:articleid' element={<UserKnowledgeArticle />} />
          <Route path='/user/knowledge/category/:categoryid' element={<UserKnowledgeCategory />} />

          {/*  <Route path='/user/knowledge/preview/article/:articleid' element={<DisplayArticleView />} /> */}
        </Route>
        <Route path='/esme' element={<EsmeLayouting />}>
          <Route path='/esme/dashboard' element={<EsmeChatContent />} />
        </Route>
      </Routes>
    </Suspense>
  )
}
export default AppRoutes
